import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';

const NotFoundPage = () => {
  return (
    <div>
      NotFoundPage
    </div>
  )
};

export default injectIntl(NotFoundPage);
